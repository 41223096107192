import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/Auth_vue.vue'
import Alarm from '../views/Alarm_vue.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/alarm',
    name: 'Alarm',
    component: Alarm,
  }, {
    path: '/auth',
    name: 'Auth',
    component: Auth,
  }, {
    path: '*',
    redirect: '/auth',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
