<template>
  <Alarm />
</template>

<script>
import Alarm from '../components/Alarm_comp.vue';

export default {
  name: 'AlarmVue',
  components: {
    Alarm,
  },
};
</script>