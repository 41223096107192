import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

Vue.use(VueAxios, axios)

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = window.location.origin
} else {
  // axios.defaults.baseURL = window.location.origin
  axios.defaults.baseURL = 'http://localhost:8190'
}

axios.defaults.timeout = 15000

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
