<template>
  <Auth />
</template>

<script>
import Auth from '../components/Auth_comp.vue'

export default {
  name: 'AuthVue',
  components: {
    Auth,
  },
}
</script>